import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './mobileApp3.module.scss';
import {getImagesFluid} from '../../componentsUtils';
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import BuyAppButtons, {urlTypes} from "../../shared/BuyAppButtons/BuyAppButtons";
import Video2 from "../../../images/sections/mobileApp3/image_sourcing.mp4";
import Video1 from "../../../images/sections/mobileApp3/log_measurement.mp4";
import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_mobileApp3"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 424, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const MobileApp3 = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = "Data Sourcing";

    return (
        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'55%'}>
                <div className={css.imageVideoWrapper}>
                    <PhoneFrameVideo
                        videoSrcURL={Video1}
                        placeholderImage={'log_measurement.jpg'}
                        imagesFluid={imagesFluid}
                        videoWidth={180}
                    />
                    <PhoneFrameVideo
                        videoSrcURL={Video2}
                        placeholderImage={'image_sourcing.jpg'}
                        imagesFluid={imagesFluid}
                        videoWidth={180}
                    />
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'45%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    TimberID image sourcing is captured in a fully automated mode.
                    <br/><br/>
                    By positioning the smartphone in front of a timber log face, the display will navigate the user by a
                    green circle appropriate placement.
                    <br/><br/>
                    The source record is created and sent to the database for further processing. Consequently, the
                    capability of the verification and authentication processes are run on demand.
                </Text>
                <BuyAppButtons urlType={urlTypes.timberId}/>
            </SectionTextWrapper>
        </div>
    )
};

export default MobileApp3;
